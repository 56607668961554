import { PATH } from "./paths"

export const LIMIT_FILE_SIZE = 10 * 1024 * 1024 // 10 MB
export const VALID_TYPES = [
  "application/pdf",
  "image/png",
  "image/jpeg",
  "image/jpg",
]

export const ICON_TYPES = {
  PDF: "application/pdf",
  PNG: "image/png",
  JPEG: "image/jpeg",
  JPG: "image/jpg",
  TXT: "text/plain"
}

export const MODE = {
  DATA_EXTRACTION: 1,
  VIEW_FILE: 2,
}

export const FILE_TYPES = {
  PDF: ['application/pdf'],
  IMAGE: ['image/jpeg', 'image/png', 'image/jpg'],
}

export const URL_PARAMS = {
  EXTRACTION_ID: 'extractionId',
  FILE_ID: 'fileId',
  OUTPUT_ID: 'ouputId',
  MODE: 'mode'
}

export const LIMIT_VERTICAL_LIST = 3

export const CHANNEL = {
  PLATFORM: 'PLATFORM',
  SFTP: 'SFTP',
  EMAIL: 'EMAIL',
}

export const STATUS = {
  COMPLETED: 'Completed',
  IN_PROGRESS: 'In Progress',
}

export const ALL_FILES_MODE = {
  WORKFLOWS: 'workflows',
  FILES: 'files',
}

export const STAGE = {
  PREVIEW_EXTRACTED: `${PATH.DEFAULT}/${PATH.DATA_EXTRACTION}`,
  PREVIEW_OUTPUT: `${PATH.DEFAULT}/${PATH.OUTPUT_PREVIEW}`,
}

export const DEFAULT_ROWS_PER_PAGE_OPTIONS = [
  { name: '50', value: '50' },
  { name: '100', value: '100' },
  { name: '200', value: '200' },
];

export const AUTOCOMPLETE_TYPE = {
  file_name: 'File Name',
  updated_by: 'Updated by',
  description: 'Description',
  created_by: 'Created by',
}

export const TOKEN = {
  ACCESS_TOKEN_KEY: 'access_token',
  REFRESH_TOKEN_KEY: 'refresh_token',
}

export const PERMISSIONS = {
  UPLOAD_AND_EXTRACT: 'idpp:extraction:upload_and_extract',
  PREVIEW_OUTPUT: 'idpp:extraction:preview_output',
  ALL_FILES: 'idpp:all_files:view',
}
