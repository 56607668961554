import UploadFiles from "../UploadFiles/UploadFiles"
import SelectFileTypes from "../SelectFileTypes/SelectFileTypes"
import ExtractLayout from "@/layouts/ExtractLayout/ExtractLayout"
import Loader from "../Loader/Loader"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { PATH } from "@/constants/paths"
import { getInputTypes, uploadFiles } from "@/apis/extract.api"
import { showError } from "@/helpers/toastify"
import { getLocalItem } from "@/helpers/storage"
import { COOKIES } from "@/constants/cookies"
import { handleError } from "@/constants/common"
import usePermissions from "@/hooks/usePermissions/usePermissions"
import { PERMISSIONS } from "@/constants/constants"

function ExtractForm() {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedFiles, setSelectedFiles] = useState<IFileSelected[]>([]);
  const [selectedType, setSelectedType] = useState<string[]>([]);
  const [types, setTypes] = useState<IInputTypes[]>([]);
  const navigate = useNavigate()
  const { hasPermission, isLoadingPermissions } = usePermissions()

  useEffect(() => {
    if (!isLoadingPermissions && !hasPermission(PERMISSIONS.UPLOAD_AND_EXTRACT)) {
      navigate(PATH.FORBIDDEN)
    }
  }, [isLoadingPermissions])

  const isDisableTypes = useMemo(
    () => selectedFiles.length === 0 || selectedFiles.some((item) => !item.isValid),
    [selectedFiles, selectedType]
  );

  const handleProceed = async () => {
    setIsLoading(true)

    const formData = new FormData();
    Array.from(selectedFiles).forEach((fileObj) => {
      formData.append('file', fileObj.file);
    });

    if(selectedType && selectedType[0]) {
      formData.append('input_type', selectedType[0]);
    }
    formData.append('description', getLocalItem(COOKIES.WORKFLOW_NAME));

    try {
      const data = await uploadFiles(formData)
      const extractionId = data.extraction_id
      const params = new URLSearchParams({ extractionId });
      navigate(`${PATH.DATA_EXTRACTION}?${params.toString()}`, { replace: true })
    } catch (error) {
      showError(handleError(error))
    } finally {
      setIsLoading(false)
    }
  }

  const handleSelectedFilesChange = (files: IFileSelected[]) => {
    setSelectedFiles(files)
  }

  const handleSelectedTypeChange = (values: string[]) => {
    setSelectedType(values)
  }

  const handleFilterItems = async(value: string) => {
    try {
      const data = await getInputTypes(value)
      const autoDetectOption = {id: '', name: 'Auto Detect'}
      setTypes([autoDetectOption, ...data])
    } catch (error) {
      showError(handleError(error))
    }
  }

  return (
    <>
      {isLoading && <Loader title="Upload & Data Extraction in Progress …" />}
      <ExtractLayout
        leftComponent={
          <UploadFiles onSelectedFilesChange={handleSelectedFilesChange} />
        }
        rightComponent={
          <SelectFileTypes
            onProceed={handleProceed}
            onSelectedTypeChange={handleSelectedTypeChange}
            isDisableTypes={isDisableTypes}
            types={types}
            onFilterItems={handleFilterItems}
          />
        }
      />
    </>
  )
}

export default ExtractForm
